<template>

  <div class="flex-container stretch space-evenly has-nav">


    <div class="align-center">
      <h1 v-show="appLevel === 0">{{ $t('nav.Buy') }}</h1>
      <h1 v-show="appLevel === 1">{{ $t('nav.signup_for_cloud') }}</h1>
    </div>

    <div class="fpanel-h">

      <div class="fpanel stretch" v-if="appLevel === 0">
        <img src="/img/i-full.svg" class="icon"/>
        <h2>{{ $t('full_version') }}</h2>
        <p v-html="$t('coming_up')"></p>
      </div>

      <div class="fpanel stretch" v-if="appLevel < 2">
        <img src="/img/i-cloud.svg" class="icon"/>
        <h2>{{ $t("cloud_version") }}</h2>
        <div v-html="$t('cloud_desc')"></div>
        <br/>

        <!--        <fi-button class="block" @click="$router.push('/signup')">{{ $t("btn_get_cloud") }}</fi-button>-->

      </div>

      <div class="fpanel stretch" v-if="appLevel == 0">
        <img src="/img/i-beta.svg" class="icon"/>
        <Beta/>
      </div>

    </div>

    <!--Apps -->
    <div class="apps align-center" v-show="appLevel < 1">
      <h3>Available soon</h3>
      <div class="flex-container is-row justify-center">
        <img src="/img/dl_ios.svg" alt="Download for iOS"/>
        <img src="/img/dl_google.svg" alt="Download for Android"/>
        <img src="/img/dl_macos.svg" alt="Download for your Mac"/>
      </div>
    </div>


    <div class="align-center">
      <small><strong>Full version privacy:</strong> Your data lives within the app. No servers, no storage anywhere
        but
        on your device.</small><br/>
      <small><strong>Cloud privacy:</strong> Your data lives within the app and gets synced with our server, located
        in
        Germany. All data is encrypted and we do not sniff or give anything to third parties.</small>
    </div>


  </div>
</template>

<style lang="scss" scoped>


.fpanel {
  margin : 1rem;
  //align-self : stretch;

  .icon {
    display    : flex;
    align-self : center;
    width      : 74px;
    margin     : 0 auto 1.5rem auto;
  }
}

.apps {
  //margin-top : 3rem;

  img {
    max-height : 60px;
    margin     : 1rem 1rem;
  }
}
</style>

<script>

import { storeToRefs }  from "pinia";
import { useUserStore } from "@/store/user";
import Beta             from "@/views/Beta";
// import FiButton         from "@/components/fiButton";

export default {
  components: {Beta},
  setup() {
    const store = useUserStore()
    const {appLevel} = storeToRefs(store)
    return {
      appLevel
    }
  }
}

</script>
