<template>
  <div class="login flex-container stretch align-vertical">

    <div class="section-container">
      <div class="fpanel">
        <h1>{{ $t('auth.reset_pass') }}</h1>

        <br/>
        <p class="message error" v-if="error">{{ error.message }}</p>

        <form>

          <div v-if="state === 'reset_FAIL'">

          </div>

          <div v-if="state === 'default' || state === 'reset_FAIL'">
            <!--            <p v-if="state ==='reset_FAIL'" class="message error">-->
            <!--              {{ $t('auth.invalid_code') }}-->
            <!--            </p>-->

            <p>{{ $t('auth.enter_email_to_reset') }}</p>

            <label for="email">{{ $t('auth.your_email') }}</label>
            <input name="email" id="email" type="email" v-model="email" :placeholder="$t('auth.your_email')"/>


            <fi-button class="button block" style="margin-top:1.5em;" @click.prevent="resetAction">{{ $t('auth.reset_pass') }}</fi-button>
            <p>
              <router-link to="/login">{{ $t('auth.login') }}</router-link>
            </p>
          </div>

          <div v-if="state === 'reset_SENT'">
            <p>{{ $t('auth.reset_pass_sent') }}</p>
            <router-link class="button" to="/login">{{ $t('auth.login') }}</router-link>
          </div>

          <div v-if="state === 'reset_BUSY'" class="align-center">
            <p>
              <icon-refresh class="white spin"/>
            </p>
            <!--              <p>{{ $t('auth.reset_pass_sent') }}</p>-->
          </div>

          <div v-if="state === 'reset_OK'">
            <p>
              <label for="password">{{ $t('auth.your_new_password') }}</label>
              <input name="password" id="password" type="password" v-model="password"/>
              <label for="password1">{{ $t('auth.repeat_password') }}</label>
              <input name="password1" id="password1" type="password" v-model="password1"/>
            </p>
            <p>
              <a class="button" @click.prevent="resetActionExecute">{{ $t('auth.reset_pass') }}</a>
            </p>
          </div>

          <div v-if="state === 'reset_DONE'">
            <p>
              {{ $t('auth.reset_success') }}
            </p>
            <router-link class="button" to="/">{{ $t('continue') }}</router-link>
          </div>


        </form>

      </div>
    </div>
  </div>
</template>

<script>


import { onMounted, reactive, ref, toRefs }          from "vue";
import { storeToRefs }                               from "pinia";
import { useRoute }                                  from "vue-router";
import { apiResetPassExecute, getUserByNameAndCode } from "@/store/api";
import { useUserStore }                              from "@/store/user";
import FiButton                                      from "@/components/fiButton";

export default {
  name: 'ResetPass',
  components: {FiButton},
  setup() {

    const route = useRoute()

    const state = ref('default')  // reset_sent, reset_BUSY, reset_OK, reset_FAIL
    const authData = reactive({email: '', password: '', password1: ''}) // Todo: remove!!!

    const store = useUserStore()
    const {error} = storeToRefs(store)

    const resetAction = () => {
      state.value = 'reset_BUSY'
      store.resetPass(authData).then(r => {
        console.log("RP ", r)
        if (r) {
          state.value = 'reset_SENT'
        } else
          state.value = 'reset_FAIL'
      })
    }

    const resetActionExecute = () => {
      state.value = 'reset_BUSY'
      // Check pass ==
      if (authData.password.length < 7) {
        store.error = {message: ' Passwords need to have at least 7 characters'}
        return
      }
      if (authData.password !== authData.password1) {
        store.error = {message: ' Passwords do not match'}
        return
      }
      //
      const {password} = authData
      // Note: url still needs the code!
      apiResetPassExecute({username: route.params.username, password: password, code: route.params.code}).then(r => {
        if (r.data.name === route.params.username) {
          error.value = null
          state.value = 'reset_DONE'
          // Login
          store.login({name: route.params.username, password: password})
        }
      })
    }

    onMounted(() => {
      if (route.params && route.params.code && route.params.username) {
        // State
        state.value = 'reset_BUSY'
        // We have a code. Check and handle
        getUserByNameAndCode(route.params.username, route.params.code).then(r => {
          if (!r.data) {
            state.value = 'reset_FAIL'
          } else {
            error.value = null
            state.value = 'reset_OK'
          }
        })

      }
    })


    return {
      state,
      resetAction,
      resetActionExecute,
      ...toRefs(authData),
      error
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  width      : auto;
  margin-top : 1em;
}
</style>
