<template>
  <div class="beta ">
      <h2 v-html="$t('joinbeta_h1')"></h2>
      <p v-html="$t('joinbeta')"></p>

      <div style="text-align: left;">
        <form v-show="showForm">
          <label for="name">{{$t('auth.your_name')}}</label>
          <input id="name" type="text" name="name" v-model="name" required/>
          <br/>
          <label for="email">{{$t('auth.your_email')}}</label>
          <input id="email" type="email" name="email" v-model="email" required/>
          <br/>
          <label for="os">{{$t('auth.your_os')}}</label>
          <select id="os" name="os" v-model="os">
            <option value="ios">iOS</option>
            <option value="android">Android</option>
          </select>
<!--          <input type="hidden" name="locale" :value=" $i18n.locale.toUpperCase()"/>-->
          <br/><br/>
          <fi-button  class="button block" @click.prevent="join">{{$t('join!')}}</fi-button>
        </form>
        <h3>{{result}}</h3>
      </div>

  </div>
</template>

<script>

import { joinBeta } from "@/store/api";
import FiButton     from "@/components/fiButton";

export default {
  name: 'Beta',
  components: {FiButton},
  data() {
    return {
      showForm: true,
      result: '',
      name: '',
      email: '',
      os: 'ios'
    }
  },
  methods: {
    join() {

      if (!this.email || !this.name) {
        return false
      }

      this.result = 'Sending...'
      this.showForm = false

      joinBeta({name:this.name, email: this.email, os: this.os, locale:  this.$i18n.locale.toUpperCase()}).then(r=>{
        if (r.status === 200) {
          this.result = 'Thanks, I will be in touch as soon as possible!'
        } else {
          this.result = 'Sorry, I could not send. Please check back soon!'
        }
      }).finally(()=>{
        // this. = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../styles/variables";

//.beta {
//  font-family: one;
//  position        : absolute;
//  left            : 0;
//  width           : 100%;
//  //height     : 100%;
//  //background : var(--primary-color);
//  z-index         : 1;
//  color           : var(--text-color);
//  padding         : 1rem;
//  overflow        : auto;
//  //z-index    : -1;
//  display         : flex;
//  flex-direction  : column;
//  justify-content : center;
//  height          : 100%;
//}
//




</style>
