<template>
  <div class="intro overlay">
    <div class="section-container">

      <div class="close">
        <icon-close class="white" v-touch:tap="closeEvent"/>
      </div>

      <div class="slide align-center">
        <img src="@/assets/fizzler-text.svg" style="width: 80%;"/>
      </div>

      <div class="slide">
        Well, err... Coming up!
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Intro',
  emits: ['close'],
  setup(props, {emit}) {
    const closeEvent = () => {
      emit('close')
    }
    return {
      closeEvent
    }
  }
}
</script>
<style lang="scss" scoped>
.section-container {
  // we got slides
  // https://www.npmjs.com/package/vue3-carousel
  display        : flex;
  flex-direction : column;
  flex-grow      : 1;
  width          : 100%;
  height         : 100%;

  .close {
    position : absolute;
    top      : 10px;
    right    : 10px;
    cursor   : pointer;
  }

  .slide {
    display         : flex;
    height          : 100%;
    align-content   : center;
    justify-content : center;
  }
}
</style>
