<template>
  <div class="flex-container stretch space-evently has-nav">

    <div class="flex-container">

<!--      <div class="align-center">-->
<!--        <img src="@/assets/fizzler-text.svg" alt="Fizzler!"-->
<!--             style="height: 62px;margin:5em auto 0 auto;"/>-->
<!--      </div>-->

      <div class="section-container center">
        <div class="fpanel box">
          <h2>{{ $t('about.title') }}</h2>
          <p v-html="$t('about.main')"></p>
        </div>
        <div class="fpanel box">
          <h2>{{ $t('about.howto_title') }}</h2>
          <p>
            {{ $t('about.howto') }}
            <strong>
              <router-link :to="'/projects'">{{ $t('ProjectManager') }}</router-link>
            </strong>
            {{ $t('about.howto_1') }}<br/>
            <fi-button class="block" v-touch:tap="toggleIntro">{{ $t('help.help') }}</fi-button>
          </p>
        </div>
        <div class="fpanel box">
          <h2>{{ $t('privacy.title') }}</h2>
          <div v-show="appLevel === 0"><span v-html="$t('auth.level_0')"></span>
            <router-link to="/options"><strong>{{ $t('auth.level_0_1') }}</strong></router-link>
            {{ $t('auth.level_0_2') }}
          </div>
          <div v-show="appLevel === 1" v-html="$t('auth.level_1')"></div>
          <div v-show="appLevel === 2"><span v-html="$t('auth.level_2')"></span>
            <router-link to="/login"><strong>{{ $t('auth.level_2_1') }}</strong></router-link>
            .
          </div>

        </div>

        <div class="fpanel box">
          <h2>Support</h2>
          Fizzler Version <strong>{{ appVersion }}</strong><br/><br/>
          {{ $t('help.support') }}<br/>
          <fi-button class="block" @click="callSupport">drop us a line</fi-button>

          <p>
            <small>Developed and designed by </small><br/>
            <a href="https://interactivesites.co.nz" target="_blank" alt="developer">
              <img src="@/assets/isites_logo.png" style="width: 270px;"/>
            </a>
          </p>
          <p>
            <strong>Marco Siebert</strong>
            <br/>Waiotahe | Opotiki
            <br/>New Zealand
          </p>
        </div>
      </div>
    </div>

    <Intro v-show="showIntro" v-on:close="toggleIntro"/>

  </div>
  <!--  </div>-->
</template>

<script>

import { ref } from "vue"
import Intro             from "@/views/Intro";
import { useUserStore }  from "@/store/user";
import { storeToRefs }   from "pinia";
import FiButton          from "@/components/fiButton";

export default {
  name: 'About',
  components: {FiButton, Intro},
  setup() {
    const showIntro = ref(false)
    const toggleIntro = () => showIntro.value = !showIntro.value
    const appVersion = ref(process.env.PACKAGE_VERSION) // same in appState store.

    const store = useUserStore()
    const {user, appLevel} = storeToRefs(store)

    // const supportMail = computed(() => `mailto:marco@interactivesites.co.nz?subject=Fizzler support request (${appVersion.value})`)

    return {
      user,
      appLevel,
      appVersion,
      showIntro,
      toggleIntro,
      callSupport: ()=>{
        location.href = `mailto:marco@interactivesites.co.nz?subject=Fizzler support request (${appVersion.value})`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.section-container {
  max-width       : 102em;
  display         : flex;
  flex-wrap       : wrap;
  justify-content : center;
}

.box {
  margin : 1rem;
}
</style>
