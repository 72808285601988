<template>
  <div class="flex-container stretch align-vertical justify-center has-nav">

    <!--      APP: {{ appLevel }}-->
    <div class="fpanel" v-if="!user || user && !user.authenticated">
      <div>
        <h2>{{ $t("auth.login") }}</h2>
        <router-link v-show="!offline" to="/options">{{ $t("auth.or_signup") }}</router-link>
      </div>
      <br/>
      <p class="message error" v-if="error">{{ error.message || $t('error.database') }}</p>
      <p class="message warning" v-if="offline">{{ $t('error.go_online') }}</p>
      <form v-show="!offline">
        <label for="name">Email</label>
        <input name="name" id="name" type="email" v-model="name" :disabled="offline"/>
        <br/>
        <label for="password">Password</label>
        <input name="password" id="password" type="password" v-model="password" :disabled="offline">
        <br/>
        <label></label>
        <fi-button class="block" :request-active="busy || apiIsBusy" :icon="'user'" @click.prevent="loginAction">
          Login
        </fi-button>
        <p>
          <router-link to="/resetpass">{{ $t('auth.forgot_password') }}</router-link>
        </p>
      </form>
    </div>

    <!-- Logged in -->
    <div class="fpanel" v-if="user && user.authenticated">
      <h3>{{ $t("auth.account") }}</h3>
      <p>{{ $t("auth.logged_in_as") }} <strong>{{ user?.metadata?.username || user.name }}</strong></p>
      <div v-show="appLevel === 0"><span v-html="$t('auth.level_0')"></span>
        <router-link to="/options"><strong>{{ $t('auth.level_0_1') }}</strong></router-link>
        {{ $t('auth.level_0_2') }}
      </div>
      <div v-show="appLevel === 1" v-html="$t('auth.level_1')"></div>
      <div v-show="appLevel === 2"><span v-html="$t('auth.level_2')"></span>
        <router-link to="/login"><strong>{{ $t('auth.level_2_1') }}</strong></router-link>
      </div>

      <hr/>

      <!-- Cloud only -->
      <div style="margin: 1rem 0;" v-if="user && user.subscription">
        <strong>{{ $t('auth.subscription') }} is {{ user.subscription.status }}</strong>

        <!-- Active subscription -->
        <div v-if="user.subsctiption === 'ACTIVE'">
          <p class="ml-2">{{ user.subscription.subscriber.name.given_name }} {{ user.subscription.subscriber.name.surname }}</p>
          <div class="columns">
            <div class="column p-0">{{ $t('auth.subscription_amount') }}&nbsp;</div>
            <div class="column p-0"></div>
          </div>
          <div class="columns">
            <div class="column p-0">{{ $t('auth.subscription_expiry') }}&nbsp;</div>
            <div class="column p-0"><strong>{{ dateFromUTC(user.subscription.billing_info.final_payment_time) }}</strong></div>
          </div>
          <div class="columns">
            <div class="column p-0">{{ $t('auth.subscription_renewal') }}&nbsp;</div>
            <div class="column p-0"><strong>{{ dateFromUTC(user.subscription.billing_info.next_billing_time) }}</strong></div>
          </div>

          <fi-button :request-active="busy || apiIsBusy" :icon="'qr'" class="block" @click.prevent="suspendUserSubscription">{{ $t("auth.suspend_subscription") }}</fi-button>
          <fi-button :request-active="busy || apiIsBusy" :icon="'qr'" class="block" @click.prevent="cancelUserSubscription">{{ $t("auth.cancel_subscription") }}</fi-button>
        </div>


      </div>

      <!-- Cancelled subscription -->
      <div v-if="user && user.subscription && user.subscription.status === 'CANCELLED' || appLevel < 2">
        <router-link to="/signup" class="button block">{{ $t('nav.signup_for_cloud') }}</router-link>
      </div>


      <!-- Suspended subscription -->
      <div v-if="user && user.subscription && user.subscription.status === 'SUSPENDED'">
        <p>{{ $t('auth.renew_subscription') }}</p>
        <fi-button :request-active="busy || apiIsBusy" :icon="'qr'" class="block" @click.prevent="resumeUserSubscription">{{ $t("auth.renew_subscription_btn") }}</fi-button>
      </div>

      <hr/>

      <!-- Logout option -->
      <fi-button v-if="user.authenticated" :request-active="busy" :icon="'user'" @click.prevent="logoutAction" class="block mt-3">
        {{ $t("auth.logout") }}
      </fi-button>

      <!--        <a class="button block" @click.prevent="logoutAction" v-if="user && appLevel === 2">{{ $t("auth.logout") }}</a>-->
    </div>

  </div>
</template>

<script>


import { reactive, ref, toRefs }                                       from "vue";
import { useRouter }                                                   from "vue-router";
import { cipher, dateFromUTC, getSalt }                                from "@/utils";
import FiButton                                                        from "@/components/fiButton";
import { useUserStore }                                                from "@/store/user";
import { storeToRefs }                                                 from "pinia";
import { cancelSubscription, resumeSubscription, suspendSubscription } from "@/payment/payment";
import { setLocalUserPrefs }                                           from "../utils";

/* eslint-disable */
export default {
  name: 'About',
  props: ['system', 'offline'],
  components: {FiButton},
  setup(props) {
    const router = useRouter()
    const store = useUserStore()
    const {user, appLevel, busy, error} = storeToRefs(store)

    // Todo: use appState.busy
    const apiIsBusy = ref(false)

    // -------- Auth
    const authData = reactive({
      name: '',
      password: ''
    })


    // ---- Login
    const loginAction = async () => {
      const _user = await store.login(authData)
      if (_user?._id && _user._id !== 'stranger') {
        // AutoLogin
        // Store this info and remind the user when he is not logged in
        // Do NOT do this in the browser
        if (props.system.app || props.system.dev) {
          const _cipher = cipher(getSalt())
          const hashedPw = _cipher(authData.password)
          setLocalUserPrefs({name: authData.name, hash: hashedPw})
        }

        // Go home
        router.push('/')
      }

    }

    // ---- Logout
    const logoutAction = async () => {
      await store.logout()
    }


    // -------- Subscriptions


    const suspendUserSubscription = async () => {
      apiIsBusy.value = true
      const r = await suspendSubscription(user.value.metadata.subscriptionId)
      console.log('suspend res = ', r)
      // if (r) {
      //   user.value.appLevel = Capacitor.getPlatform() !== 'web' ? 1 : 0  // reset appLevel
      // }
      apiIsBusy.value = false
    }

    // Cancel
    const cancelUserSubscription = async () => {
      apiIsBusy.value = true
      const r = await cancelSubscription(user.value.metadata.subscriptionId)
      // if (r) {
      //   user.value.appLevel = Capacitor.getPlatform() !== 'web' ? 1 : 0  // reset appLevel
      // }
      apiIsBusy.value = false
    }

    const resumeUserSubscription = async () => {
      apiIsBusy.value = true
      const r = await resumeSubscription(user.value.metadata.subscriptionId)
      // if (r) {
      //   console.log('RES ', r)
      //   user.value.appLevel = 2
      // }
      apiIsBusy.value = false
    }


    return {
      // requestActive: store.state.appState.requestActive,
      busy,
      apiIsBusy,
      appLevel,
      suspendUserSubscription,
      cancelUserSubscription,
      resumeUserSubscription,
      loginAction,
      logoutAction,
      ...toRefs(authData),
      dateFromUTC: dateFromUTC,
      user,
      error
    }
  }
}
</script>
